import * as React from 'react'
import '../app.scss'

//Gatsby needs a index page to build correctly, even if it does not contain anything.

const OldIndex = () => {
  return <></>
}

export default OldIndex
